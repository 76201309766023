/* You can add global styles to this file, and also import other style files */
@import "variables";

@import "node_modules/bootstrap/scss/bootstrap";

@import "node_modules/angular-notifier/styles";

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "custom";

// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import '~@circlon/angular-tree-component/css/angular-tree-component.css';
@import "@ali-hm/angular-tree-component/css/angular-tree-component.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
  overflow-x: hidden;
}

.board-task-filter-datepicker-container .bs-datepicker-container {
  margin-right: 135px;
}

div.multiselect-dropdown {
  span.dropdown-btn {
    span.selected-item {
      span {
        white-space: nowrap;
      }
    }
  }
}

.task-groups {
  .multiselect-dropdown {
    .dropdown-list {
      transform: translateY(-112%);
      overflow: hidden;
    }
  }
}

.visually-hidden {
  display: none;
}

app-task-edit typeahead-container {
  width: calc(100% - 30px);
  height: 200px !important;
}


@media (max-width: 960px) {
  .formio-component-datagrid {
    width: 100%;
    position: relative;
    overflow-x: auto;
    table {
      min-width: 750px;
    }
  }

  .datagrid-table > tbody > tr > td {
    word-break: normal !important;
  }

  .datagrid-table {
    th {
      min-width: 250px !important;
    }

    th:last-of-type{
      min-width: auto !important;
    }
  }

  .choices__item.choices__item--selectable {
    text-overflow: inherit !important;
    overflow: visible;
  }
}
