
/***************************************************************
 ******************** FMS custom variables *********************
 **************************************************************/
$fms-white: #fff;
$fms-black: #000;

$fms-gray-text: #252525;

//primary
$fms-blue: #1161be;
//$fms-blue-dark: #00264f;
$fms-blue-light: #e1eefe;
$fms-gray-dark: #252525;
$fms-gray: #8291ae;
$fms-gray-light: #f7f8fc;

// $fms-gray-tone: #f0f3f2;
// $fms-gray-bg: #f9fbfc;
// $fms-gray-border: #e9eef0;
// $fms-link-color: #248cfc;
$fms-green: #1ba953;
$fms-green-light: #daf8e6;

$fms-yellow: #fbcd1e;
$fms-yellow-light: #fff4cb;
//secondary


$fms-blue-secondary: #bee7ff;

$fms-purple: #ebe8fc;
$fms-orange: #ff612c;
$fms-red: #f64540;
$fms-pink: #ff5b84;



$fms-error: $fms-red;
$fms-pending: $fms-yellow;
$fms-positive: $fms-green;
$fms-padding-x: 1.375rem;
// $fms-grid-border-color: $fms-gray-light;
// $fms-grid-color: $fms-gray-dark;
// $fms-grid-header-background-color: $fms-white; //$fms-gray-light;
// $fms-grid-row-even-background-color: $fms-white;
// $fms-grid-row-hover-background-color:#E1F1F7;
// $fms-grid-row-padding-y: 1.4rem; //original 1.5rem
// $fms-checkbox-size: 1.25rem;
// $fms-checkbox-size-sm: .875rem;
// $fms-checkbox-size-lg: 1.875rem;
$fms-font-family: 'Noto Sans';
// $fms-font-family-medium: "Graphik Medium";
// $fms-font-family-bold: "Graphik Bold'";



/***************************************************************
 *************** Overriding bootstrap variables before ****************
 **************************************************************/


$btn-disabled-opacity: .35;
$input-btn-focus-box-shadow: none;
$input-btn-active-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

$body-bg: $fms-gray-light;
$body-color: $fms-gray-dark;



@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';




/***************************************************************
 *************** Overriding bootstrap variables after ****************
 **************************************************************/

$grid-breakpoints: (xs: 0,
sm: 1px,
md: 2px,
lg: 3px,
xl: 100px);


//Fonts
$font-family-sans-serif: $fms-font-family;
$font-family-base: $font-family-sans-serif;


$link-color: $fms-blue;
//$link-color-active: $fms-blue;

$theme-colors: ("primary": $fms-blue,
"warning": $fms-pending,
"danger": $fms-red,
"info": $fms-blue,
"success": $fms-green);



//Headings
//$headings-font-family: $fms-font-family-medium;
$headings-color: $fms-gray-dark;
$headings-font-weight: bold;
// $headings-margin-bottom: 1.5rem;
// $h1-font-size: 2.44125rem;
// $h2-font-size: 1.953125rem;
// $h3-font-size: 1.563125rem;
// $h4-font-size: 1.25rem;
// $h1-line-height: 3.125rem; //fms var
// $h2-line-height: 2.625rem; //fms var
// $h3-line-height: 1.875rem; //fms var
// $h4-line-height: 1.5rem; //fms var

//Nav
$navbar-padding-y: $spacer *  0.75;
$navbar-padding-x: $spacer * 2;

$navbar-light-color: $fms-gray-text;
$navbar-light-hover-color: $link-hover-color;
$navbar-light-active-color: $link-hover-color;
$navbar-light-disabled-color: rgba($fms-gray-text, .3);


//Card

$card-cap-bg: $fms-white;
$card-bg: $fms-gray-light;

//Table

$table-bg: $fms-white;
$table-accent-bg: $fms-gray-light;
$table-hover-bg: rgba($component-active-bg, .2);
$table-active-bg:  rgba($component-active-bg, .3);

//Small text
// $small-font-size: 0.8125rem;
// $small-line-height: 1.125rem; //bac var

// Buttons + Forms

//
// $btn-padding-y: .575rem;
// $btn-padding-y-lg: $btn-padding-y;
// $btn-padding-x-sm: 1.25rem;
// $btn-padding-y-sm: .40625rem;
// $btn-border-radius: .3rem;
// $btn-border-radius-lg: $btn-border-radius;
// $btn-border-radius-sm: $btn-border-radius;
// $input-color: $fms-gray-dark;
// $input-border-radius: 0;
// $input-border-radius-lg: $input-border-radius;
// $input-border-radius-sm: $input-border-radius;
// $input-border-color: $fms-gray;
// $input-border-width: 0.0625rem; //1px

// $input-padding-x: .75rem;
// $input-padding-y: .938rem;
// $form-group-margin-bottom: 2rem;

//Paragrahs
//$paragraph-margin-bottom: .5rem;

//Cards
// $card-border-color: $fms-gray-border;
// $card-spacer-x: $fms-padding-x;


/* Alerts */
// $alert-padding-y: .5rem;
// $alert-padding-x: .625rem;

/* Badges */

// $badge-padding-x: 1rem;
// $badge-padding-y: .2rem;
// $badge-border-radius: .25rem;

/* Modals */
// $modal-lg: 684px;
// $modal-content-border-radius: 0;
// $modal-backdrop-bg: $fms-blue-dark;
// $modal-backdrop-opacity: .8;

/* Dropdowns */
//$dropdown-item-padding-x: .9375rem
