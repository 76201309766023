@import "variables";

body {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

/* Bootstrap overrides */

.table {
  thead {
    th {
      border: none;
    }
  }
}

table.fixed-layout {
  table-layout: fixed;
}

td.vertical-align-middle {
  vertical-align: middle !important;
}

h1 {
  font-family: $fms-font-family;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0;
}

h2 {
  font-family: $fms-font-family;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
}

h3 {
  font-family: $fms-font-family;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

h4 {
  font-family: $fms-font-family;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

h5 {
  font-family: $fms-font-family;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

h6 {
  font-family: $fms-font-family;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}

.btn {
  font-weight: bold;
  border-width: 2px;
}

a.btn {
  font-size: 1rem;
}

.btn-secondary {
  background-color: $fms-gray;
  border-color: $fms-gray;
}

.btn-outline-secondary {
  color: $fms-gray;
  border-color: $fms-gray;
}

a.link,
a.link:not([href]):not([tabindex]) {
  font-size: 14px;
  font-weight: bold;
  color: $fms-blue;
  cursor: pointer;
}

a.link-danger {
  font-size: 14px;
  font-weight: bold;
  color: $fms-red !important;
  cursor: pointer;
}

a.link:hover {
  color: #0056b3;
}

a.in-text-link {
  font-size: 14px !important;
  font-weight: normal !important;
  text-decoration: none !important;
  color: $fms-blue;
  cursor: pointer;
}

.bs-datepicker.archived-task-range {
  right: 126px;
}

.bs-datepicker.report-range {
  top: 2px;
  left: 180px;
}

tabset {
  background-color: #fff;

  ul.nav-tabs {
    background-color: #fff;

    a.nav-link {
      border-width: 0 0 2px 0;
      border-bottom: 2px solid transparent;
      color: rgba(0, 0, 0, 0.87);
      font-family: $fms-font-family;
      font-size: 18px;
      font-weight: bold;
      min-width: 160px;
      // opacity: 0.6;
      padding: 7px 24px;
      text-align: center;
    }

    a.nav-link.active,
    a.nav-link:hover {
      /*border-bottom: 2px solid rgba(0, 0, 0, .87);*/
    }

    a.nav-link.active {
      background-color: #fff;
      color: $fms-blue;
      border-bottom: 2px solid $fms-blue;
    }
  }
}

.bck-white {
  background: $fms-white;
}

.bck-gray {
  background: $fms-gray-light;
}

.bck-blue {
  background: $fms-blue;
  color: $fms-white;
}

.bck-blue-light {
  background: $fms-blue-light;
}

.cursor-pointer {
  cursor: pointer;
}

.white-box {
  background-color: #fff;
  padding: 1.5rem;
}

.blue-box {
  background-color: $fms-blue-light;
  padding: 1rem 1.5rem;
}

.light-gray-box {
  background-color: $fms-gray-light;
  padding: 0.75rem;
}

.card-body {
  .main-container {
    padding: 34px;
  }
}

input.rtl {
  direction: rtl;
}

.flex-grow {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.no-flex-shrink {
  flex-shrink: 0;
}

.navbar {
  z-index: 100;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.wide-modal {
  max-width: 60vw !important;
}

.remove-icon {
  color: $fms-red;
  cursor: pointer;
}

.disabled-icon {
  color: $fms-gray
}

.input-group.with-icon {
  input.form-control {
    border-right-width: 0;
  }

  .input-group-text {
    background-color: #fff;
  }
}

.badge.badge-button {
  position: absolute;
  top: -10px;
  right: -10px;
  display: block;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 0.4em;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.35;
}

.task-tags {
  height: 18px;
  line-height: 0;
  padding: 0;
}

.task-tag {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  padding: 0 8px;
  border-radius: 9px;
  margin-right: 5px;
}

.task-tag.category {
  color: $fms-gray;
  background-color: #e6e9f0;
}

.task-tag.type {
  color: #151b26;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-decoration-underline {
  text-decoration: underline;
}

[ng-reflect-router-link] {
  cursor: pointer;
}

i-feather.blue-icon {
  color: $fms-blue;
  cursor: pointer;
}

i-feather.white-icon {
  color: $fms-white;
  cursor: pointer;
}


.modal-dialog.modal-image {
  width: auto;
  max-width: initial;

  .modal-content {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0;
  }
}

.float-right {
  float: right;
}


.color-yellow {
  color: $fms-yellow;
}

@keyframes loading-indicator {
  to {
    transform: rotate(360deg);
  }
}

.loading-indicator {
  position: relative;
}

.loading-indicator .blocker {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: progress;
  background-color: rgba(255, 255, 255, 0.7);
}

.loading-indicator::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #07d;
  border-bottom-color: #07d;
  animation: .6s linear infinite loading-indicator;
  z-index: 2;
}

.loading-indicator.for-dropdown::after {
  bottom: 6px;
  right: 6px;
  width: 20px;
  height: 20px;
  background-color: #fff;
}

.loading-indicator.for-element::after {
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.star-icon, .star-icon::before {
  font-size: 1em !important;
  color: #8291ae !important;
}

.notifier__container {
  position: absolute;
  top: 0;
  left: 0;
}

table.table {
  margin-bottom: initial;
}

.sidenav-content {
  height: 100%;

  .sidenav-body {
    padding: 25px 20px;
    height: calc(100% - 60.5px);
    overflow-y: auto;
  }

  .sidenav-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    padding: 12px 20px;
  }
}


/* structure components styling */
.Country-list {
  border-right: solid 1px $fms-blue-light;
  height: calc(100vh - 215px);
}

.Country-item {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 -1px 0 0 $fms-blue-light;
}

.Country-header {
  font-size: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: bold;
}

.country-detail-item {
  color: $fms-white;
  padding-left: 10px;
  margin-top: 20px;
  padding-top: 8px;
  background: $fms-blue;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
}

.governorate-detail-item {
  padding-left: 10px;
  margin-top: 10px;
  padding-top: 8px;
  color: $fms-blue;
  background: $fms-blue-secondary;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
}

.district-detail-item {
  padding-left: 10px;
  margin-top: 10px;
  padding-top: 8px;
  color: $fms-blue;
  background: $fms-blue-light;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
}


.unit-detail-item {
  padding-left: 10px;
  margin-top: 10px;
  padding-top: 8px;
  background: $fms-gray-light;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
}


ul.org-unit {
  list-style: none;
}

ul.org-unit li::before {
  content: " ";
  color: $fms-blue;
  font-size: 0px;
  /* font-weight: bold; */
  display: inline-block;
  position: relative;
  right: 35px;
  top: -3px;
  /* margin-left: 20em; */
  background: $fms-blue;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

ul.org-unit li::after {
  content: " ";
  border-left: 1px solid $fms-blue;
  border-bottom: 1px solid $fms-blue;
  height: 50px !important;
  top: -62px;
  width: 16px;
  position: relative;
  display: block;
  left: -50px;
}

ul.org-unit > ul > li::after {
  content: " ";
  border-left: 1px solid $fms-blue;
  border-bottom: 1px solid $fms-blue;
  height: 50px !important;
  top: -62px;
  width: 55px;
  position: relative;
  display: block;
  left: -90px;
}

ul.org-unit {
  margin-bottom: 0 !important;
}

.no-children {
  width: 32px;
  height: 30px;
}

ul.org-unit > ul > ul > li::after {
  content: " ";
  border-left: 1px solid #1161be;
  border-bottom: 1px solid #1161be;
  height: 50px !important;
  top: -62px;
  width: 95px;
  position: relative;
  display: block;
  left: -130px;
}

ul.org-unit > ul > ul > ul > li::after {
  content: " ";
  border-left: 1px solid #1161be;
  border-bottom: 1px solid #1161be;
  height: 50px !important;
  top: -62px;
  width: 135px;
  position: relative;
  display: block;
  left: -170px;
}

ul.org-unit > ul > ul > ul > ul > li::after {
  content: " ";
  border-left: 1px solid #1161be;
  border-bottom: 1px solid #1161be;
  height: 50px !important;
  top: -62px;
  width: 175px;
  position: relative;
  display: block;
  left: -210px;
}

ul.org-unit > ul > ul > ul > ul > ul > li::after {
  content: " ";
  border-left: 1px solid #1161be;
  border-bottom: 1px solid #1161be;
  height: 50px !important;
  top: -62px;
  width: 215px;
  position: relative;
  display: block;
  left: -250px;
}

ul.org-unit > ul > ul > ul > ul > ul > ul > li::after {
  content: " ";
  border-left: 1px solid #1161be;
  border-bottom: 1px solid #1161be;
  height: 50px !important;
  top: -62px;
  width: 255px;
  position: relative;
  display: block;
  left: -290px;
}

.nav-link.country-name {
  padding: 0;
}

.country-name-container {
  list-style-type: none;
  padding: 0;
}

.country-name-container a {
  color: white;
}

.country-name-container .nav-link {
  color: #292727;
}
